import React from 'react';
import './BigIcon.css';

type BigIconProps = {
  icon: string,
  text: string,
  url: string
}

function BigIcon({ icon, text, url }: BigIconProps) {
  return (
    <button className="pushable" onClick={() => {
      try {
        const checkUrl = new URL(url);
        const redirectTo = url;

        console.log()

        if (checkUrl.hostname === "curr") {
          window.location.href = redirectTo.replace(checkUrl.hostname, window.location.hostname);
        } else {
          window.location.href = url;
        }
      } catch (e) {
        console.error(`Invalid url in config for - \'${text}\'`)
      }
    }}>
      <span className="front">
        <img src={icon} style={{ width: "200px", height: "200px"}}/>
        <p>{text}</p>
      </span>
    </button>
  );
}

export default BigIcon;
