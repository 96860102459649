import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import BigIcon from './component/BigIcon';

interface AppConfig {
  title: string;
  url: string;
  iconPath: string;
}

function App() {
  const [appConfig, setAppConfig] = useState<AppConfig[]>([]);

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => {setAppConfig(data)})
      .catch((error) => console.error("Error loading config:", error));
  }, []);
  
  return (
    <div className="App">
      <div className="IconContainer">
        {appConfig.map(item => (<BigIcon key={item.title} icon={item.iconPath} text={item.title} url={item.url}/>))}

      </div>
    </div>
  );
}

export default App;
